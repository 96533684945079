import { FullPageElement, html, css } from 'Elements';
import { Router } from 'Router';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

class CTIHome extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      css`

      .background_home {
        background-color:#164366;
        min-height:25vh;
        display:flex;
        position:relative;
      }

      .background_home_img {
        position:absolute;
        display:flex;
        min-height:25vh;
        width:100%;
        background-size: cover;
        background-image: url('assets/img/visuals/magnifier.jpg');
        opacity: 1:
      }

      .big_title {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, transparent 0%, #111111bb 20%, #111111bb 80%, transparent 100%);
        padding:10px;
        width:100%;
        color:white;
        text-transform:uppercase;
        text-shadow: 1px 1px 1px #000000;
        font-size:2em;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:top;
        flex-direction:column;
      }

      .center {
        text-align:center;
        display:block;
        padding:20px;
      }

      .flex {
        padding:20px;
        display:flex;
        gap:20px;
      }

      .flex .left {
        text-align:center;
        flex:1;
      }

      .flex .right {
        width:350px;
      }

      .exts {
        display:flex;
        flex-direction:column;
        gap:15px;
        padding:10px;
      }

      .ext {
        display:flex;
        align-items:center;
        gap:10px;
        min-width:250px;
      }

      .ext img {
        height:30px;
      }

      a {
        color:var(--sl-color-primary-700);
        position:relative;
      }

      a:hover {
        text-decoration:underline !important;
      }

      a:after {
        position:absolute;
        content: "\\e89e";
        display: block;
        right:0px;
        top:14%;
        position: absolute;
        font-family: "Material Symbols Sharp";
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        letter-spacing: normal;
        text-transform: none;
        color:var(--sl-color-primary-500);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        font-feature-settings: "liga";
        user-select:none;
      }

      .text {
        text-align:left;
        margin-left:20px;
        margin-right:20px;
      }

      sl-button {
        min-width:350px;
        font-weight:bold;
      }

      @media (max-width: 627px) {
        .flex {
          padding:5px;
          flex-direction:column;
          gap:40px;
        }
      }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            main:{
              title:'Cybersecurity incidents',
              text:'Declare or follow a security incident, make a request for assistance or follow a doubt removal.',
              button:'Access my folder'
            }
          },
        },
        french:{
          translation: {
            main:{
              title:'Traitement des incidents Cybersécurité',
              text:`Cette page vous permet de <b>déclarer</b> un évènement de sécurité, effectuer une <b>demande d'assistance</b>, ou encore procéder à une <b>levée de doute</b>.
              <br/><br/>Vous pouvez également <b>consulter</b> vos déclarations et <b>suivre</b> l'évolution de leur traitement.`,
              button1:'Déclarer un évènement de sécurité ',
              button2:'Consulter une déclaration existante',
            }
          }
        }
      }
    ]
  }
  
  render() {
    return html`
      <div class="background_home">
        <div class="background_home_img"></div>
          <div class="big_title"><div>${unsafeHTML(this._tl('main.title'))}</div></div>
        </div>
      </div>
      <br/>
      <div class="flex">
        <div class="left">
            <div class="text">${unsafeHTML(this._tl('main.text'))}</div>
            <br/><br/>
            <a-route href="./create"><sl-button pill size="large" variant="danger">${this._tl('main.button1')}</sl-button></a-route>
            <br/><br/><br/>
            <sl-button @click="${this.goToFolder}" pill size="large" variant="primary">${this._tl('main.button2')}</sl-button>
        </div>
        <div class="right">
          <box-styled variant="container">
            <div class="exts">
              <a href="https://cyber.gouv.fr/notifications-reglementaires" target="external">
                <div class="ext">
                  <img src="assets/img/anssi.svg"></img>
                  <div>Assistance OIV / prestataire qualifié</div>
                </div>
              </a>
              <a href="https://www.cybermalveillance.gouv.fr/accueil-assistance/" target="external">
                <div class="ext">
                  <img src="assets/img/cyberm.png"></img>
                  <div>Assistance aux particuliers</div>
                </div>
              </a>
              <a href="https://www.pre-plainte-en-ligne.gouv.fr/" target="external">
                <div class="ext">
                  <img src="assets/img/cyberm.png"></img>
                  <div>Pré-plainte en ligne</div>
                </div>
              </a>
              <a href="https://www.cnil.fr/fr/adresser-une-plainte" target="external">
                <div class="ext">
                  <img src="assets/img/cnil.ico"></img>
                  <div>Déclaration incident RGPD CNIL</div>
                </div>
              </a>
            </div>
          </box-styled>
        </div>
      </div>
      <br/>
    `;
  }

  goToFolder() {
    Router.go('./create');
    window.scrollTo(0, 0);
  }

}

customElements.define('page-cti', CTIHome);